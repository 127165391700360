<template>
  <div>
    <a-button @click.prevent="openModal" type="primary">+ Add Exception</a-button>

    <a-modal v-model="showModal" title="Add Exception" :footer="null" :width="900">

      <loading-screen :is-loading="isSaving"></loading-screen>

      <!-- Description -->
      <a-alert style="margin-bottom: 25px"
        message="Exceptions are periods of time when a user is not available to respond to incidents. This overrides the responder timetable.">
      </a-alert>
      <!-- / Description -->

      <!-- Loaded -->
      <div>
        <div class="form">
          <!-- Will Be -->
          <a-form-item label="Will Be">
            <a-radio-group v-model="form.available">
              <a-radio :value="false">Unavailable</a-radio>
              <a-radio :value="true">Available</a-radio>
            </a-radio-group>
          </a-form-item>
          <!-- / Will Be -->

          <!-- Start -->
          <a-row :gutter="100">
            <a-col :span="8">
              <a-form-item label="Start Date">
                <a-date-picker style="width: 100%" v-model="form.startDate" />
              </a-form-item>
            </a-col>
            <a-col :span="16">
              <a-form-item :label="`Start Time (${formatter(form.startTime)})`">
                <a-slider v-model="form.startTime" :tip-formatter="(value) => formatter(value)" :min="0" :max="1440"
                  :step="5" />
              </a-form-item>
            </a-col>
          </a-row>
          <!-- / Start -->

          <!-- End -->
          <a-row :gutter="100">
            <a-col :span="8">
              <a-form-item label="End Date">
                <a-date-picker style="width: 100%" v-model="form.endDate" />
              </a-form-item>
            </a-col>
            <a-col :span="16">
              <a-form-item :label="`End Time (${formatter(form.endTime)})`">
                <a-slider v-model="form.endTime" :tip-formatter="(value) => formatter(value)" :min="0" :max="1440"
                  :step="5" />
              </a-form-item>
            </a-col>
          </a-row>
          <!-- / End -->
        </div>

        <a-button @click.prevent="save" class="btn-rounded save-button" size="large" type="primary">Save</a-button>
      </div>
      <!-- / Loaded -->

    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
import availability from "../../../../../../../api/availability";
import LoadingScreen from "../../../../../../../components/LoadingScreen.vue";

export default {
  props: ['tenantId', 'org', 'user'],

  components: { LoadingScreen },

  data() {
    return {
      showModal: false,

      isSaving: false,
      form: {
        available: false,
        startDate: moment().add("day"),
        startTime: 540,
        endDate: moment().add("day"),
        endTime: 1005,
      },
    }
  },

  methods: {
    formatter(value) {
      let hr = Math.floor(Number(value) / 60);
      let min = value % 60;

      return String(hr).padStart(2, "0") + ":" + String(min).padStart(2, "0");
    },

    openModal() {
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
    },

    save() {
      let vm = this;
      vm.isSaving = true;

      let startHour = Math.floor(this.form.startTime / 60);
      let startMinute = this.form.startTime % 60;
      let startDateTime = this.form.startDate
        .clone()
        .set("hour", startHour)
        .set("minute", startMinute);

      let startInstant = startDateTime.format(
        "YYYY-MM-DD[T]HH:mm:ss.SSSSSSS[Z]"
      );

      let endHour = Math.floor(this.form.endTime / 60);
      let endMinute = this.form.endTime % 60;
      let endDateTime = this.form.endDate
        .clone()
        .set("hour", endHour)
        .set("minute", endMinute);

      let endInstant = endDateTime.format("YYYY-MM-DD[T]HH:mm:ss.SSSSSSS[Z]");

      let params = {
        OwnerId: vm.org.id,
        PresenceId: vm.user.id,
        StartInstant: startInstant,
        EndInstant: endInstant,
        // StartInstant: "2023-01-01T00:00:00.000000000Z",
        // EndInstant: "2023-05-15T00:00:00.000000000Z",
        Available: this.form.available,
      };
      availability
        .addOverride(this.tenantId, params)
        .then(() => {
          vm.isSaving = false;
          vm.$message.success("Exception saved successfully");
          vm.form = {
            available: false,
            startDate: moment().add("day"),
            startTime: 540,
            endDate: moment().add("day"),
            endTime: 1005,
          };
          vm.$emit('updated');
          vm.closeModal();
        })
        .catch((e) => {
          console.log(e);
          vm.isSaving = false;
          vm.$message.error("Error saving exception");
        });
    },
  }
}
</script>

<style></style>